/* You can add global styles to this file, and also import other style files */
@import '~@ctrl/ngx-emoji-mart/picker';
@import '~ngx-toastr/toastr';
@import "~@ng-select/ng-select/themes/default.theme.css";
// ... or 
@import "~@ng-select/ng-select/themes/material.theme.css";



@import url(https://fonts.googleapis.com/css2?family=Nunito:wght@300;400;600&display=swap);

@font-face {
  font-family: Inter;
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(assets/fonts/inter-light.woff2) format("woff2"), url(assets/fonts/inter-light.woff) format("woff")
}

;

@font-face {
  font-family: Inter;
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(assets/fonts/inter-regular.woff2) format("woff2"), url(assets/fonts/inter-regular.woff) format("woff")
}

;

@font-face {
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(assets/fonts/inter-medium.woff2) format("woff2"), url(assets/fonts/inter-medium.woff) format("woff")
}

;

@font-face {
  font-family: Inter;
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(assets/fonts/inter-bold.woff2) format("woff2"), url(assets/fonts/inter-bold.woff2) format("woff")
}

;

.authentication-bg {
  background-image: url(assets/images/authentication-bg.jpg);
}

$primary-color:#F72374;

a:not([href]) {
  cursor: pointer;
}


// rating
  .stars label.star.ng-star-inserted {
    color: rgb(247 35 116) !important;
     font-size: 13px;
     cursor: pointer;
}
.stars{
  width: 100% !important;
}
.stars form {
    float: left !important;
}
.stars2 form {
    float: left !important;
        display: flex;
    direction: rtl;
}

.stars label.star {
    color: rgb(247 35 116) !important;
    font-size: 13px;
}

.stars label.star {
    padding: 0px !important;
    margin-right:3px;
}

.stars label.star:before {
    color: rgb(247 35 116) !important;
}
/*****  start Checkbox *****/
.checkbox-box {
  width: 20px;
  display: block;
  position: relative;
  cursor: pointer;
  margin-bottom: 0px;
}
.highlightText{
   color: rgb(232 62 140) !important;
}

.checkbox-box span {
  font-size: 0px;
  font-weight: 400;
  font-style: normal;
  letter-spacing: normal;
  line-height: 20px;
  text-align: left;
  font-family: 'Poppins', sans-serif;
  display: inline-block;
}

.checkbox-box span:after {
  top: 0px;
  content: "";
  width: 20px;
  height: 20px;
  border: 1px solid #c1c1c1;
  background-color: #ffffff;
  position: absolute;
  left: 0px;
}

.checkbox-box input {
  position: absolute;
  width: 20px;
  left: 0px;
  opacity: 0;
  z-index: +9999;
  height: 20px;
  cursor: pointer;
}


.checkbox-box .checkbox-box input[type=checkbox]:checked+span:after {
  border-color: $primary-color;
}

.checkbox-box span:before {
  top: 0px;
  content: "\F0E1E";
  position: absolute;
  width: 20px;
  height: 20px;
  left: 0px;
  z-index: 9;
  margin: 0;
  opacity: 0;
  text-align: center;
  line-height: 25px;
  font: normal normal normal 24px/1 "Material Design Icons";
  font-size: 16px;
  line-height: 20px;
  color: #28a745;
}

.checkbox-box input[type=checkbox]:checked+span:before {
  opacity: 1;
}

.checkbox-false span:before {
  content: "\F1398";
  color: #ff3d60 !important;
}

.action-checkbox {
  display: flex;

}

/***** End Checkbox *****/

/*****  start Switch *****/
.switch {
  position: relative;
  display: inline-block;
  width: 36px;
  height: 20px;
  margin: 0px;
}

.switch input {
  display: none;
}

.switch .slider.round {
  border-radius: 26px;
}

.switch .slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.switch input:checked+.slider:before {
  -webkit-transform: translateX(14px);
  -ms-transform: translateX(14px);
  transform: translateX(14px);
}

.switch .slider.round:before {
  border-radius: 50%;
}

.switch .slider:before {
  position: absolute;
  content: "";
  height: 14px;
  width: 14px;
  left: 4px;
  bottom: 3px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

.switch input:checked+.slider {
  background-color: #28a745;
}

.switch .checkbox label::after {
  width: auto;
}

/***** End Switch *****/

/*****  start Choose file upload  *****/
.custom_file input {
  overflow: hidden;
  position: relative;
  display: inline-block;
  width: 100%;
  height: calc(1.5em + .94rem + 2px);
  margin-bottom: 0;
  border: 1px solid #ced4da;
  border-radius: .25rem;
  outline: none;
}

.custom_file input::-webkit-file-upload-button {
  border: 0px !important;
  background: #ced4da !important;
  height: 37px !important;
  outline: none;
}

/***** End Choose file upload *****/

/*****  start Alert Popup  *****/
.ion-overlay-wrapper {
  border-radius: 13px;
  overflow: hidden;
  display: flex;
  background: #f9f9f9;
  -ms-flex-direction: column;
  flex-direction: column;
  position: absolute;
  z-index: 9999;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  min-width: 250px;
  display: inline-block;
  width: 250px;
  font-family: Poppins, sans-serif;
}

.sc-ion-alert-ios-s {
  height: 100%;
  width: 100%;
}

.alert-head.sc-ion-alert-ios {
  padding-top: 12px;
  padding-bottom: 7px;
  text-align: center;
  padding-inline-start: 16px;
  padding-inline-end: 16px;
}

.alert-head.sc-ion-alert-ios h2 {
  margin-top: 8px;
  color: #000;
  font-size: 17px;
  font-weight: 600;
  line-height: 20px;
  padding: 0px;
  margin-bottom: 0px;
}

.alert-message.sc-ion-alert-ios {
  -webkit-padding-start: 16px;
  padding-inline-start: 16px;
  -webkit-padding-end: 16px;
  padding-inline-end: 16px;
  padding-top: 0;
  padding-bottom: 21px;
  color: #000;
  font-size: 12px;
  text-align: center;
  font-family: Poppins, sans-serif;
}

button.alert-button {
  outline: none;
  margin-left: 0;
  margin-right: 0;
  margin-top: 0;
  margin-bottom: 0;
  border-radius: 0;
  min-width: 50%;
  height: 44px;
  border-top: 0.55px solid #0000003b;
  border-right: 0.55px solid #0000003b;
  background-color: transparent;
  color: $primary-color;
  font-size: 17px;
  /* overflow: hidden; */
  border-bottom: 0px !important;
  border-left: 0;
  font-weight: bold;
}

.alert-button-group.sc-ion-alert-ios {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: row;
  flex-direction: row;
  width: 100%;
  margin-right: unset;
  -webkit-margin-end: -0.55px;
  margin-inline-end: -0.55px;
  margin-right: -0.55px;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

button.alert-button+button.alert-button {
  border-right: 0px !important;
  border-left: 0px !important;
}

.sc-ion-alert-ios-s .sc-ion-alert-ios-backdrop {
  background: #00000054;
  position: absolute;
  left: 0px;
  top: 0px;
  display: block;
  height: 100%;
  width: 100%;
  z-index: 9999;
}

.alert_popup .modal-content {
  background: none;
  border: 0px;
}

// .modal {
//   background: #00000085;
// }
/*****  End Alert Popup  *****/

/*****  start Sidebar menu *****/
#sidebar-menu ul li a {
  text-transform: capitalize;
}

.vertical-collpsed .vertical-menu #sidebar-menu>ul>li:hover>a {
  background: #293041;
  color: #d7e4ec;
}

.metismenu li.active a *,
.metismenu li:hover a * {
  color: #ffff !important;
}

@media (max-width: 992px) {
  .sidebar-enable .vertical-menu {
    display: block;
    width: 240px !important;
    left: 0px !important;
    transition: all 0.3s;
  }

  .vertical-collpsed .vertical-menu #sidebar-menu>ul>li>a span {
    display: contents;
    padding-left: 0px;
  }

  div#layout-wrapper .vertical-menu {
    display: block;
    left: -300px;
    transition: all 0.3s;
    z-index: 999;
    position: fixed;
  }

  body #layout-wrapper .navbar-brand-box {
    width: 73px !important;
  }

  .navbar-brand-box {
    display: block !important;
  }

  .vertical-collpsed .footer {
    left: 0px !important;
  }

  .vertical-collpsed .vertical-menu #sidebar-menu>ul>li>a {
    background: transparent !important;
    width: auto !important;
  }
}

/*****  End Sidebar menu  *****/

.full-button {
  min-width: 100% !important;
}

body {
  overflow-x: hidden;
}

.copyright a {
  color: #505d69;
}

/***** Colors *****/
.custom-control-input:checked~.custom-control-label::before,
.page-item.active .page-link {
  border-color: $primary-color;
  background-color: $primary-color;
}

.btn-primary {
  background: $primary-color !important;
  border-color: $primary-color !important;
}

.text-primary,
body a.text-primary:focus,
body a.text-primary:hover,
.text-solitud:hover {
  color: $primary-color !important;
}

.auth-form-group-custom .auti-custom-input-icon {
  color: $primary-color !important;
}

.btn-warning,
.custom-accordion-arrow .card-header .accor-arrow-icon {
  box-shadow: none !important;
  background-color: $primary-color !important;
  border-color: $primary-color !important;
}

/***** apexcharts *****/
// .apexcharts-data-labels text {
// 	fill: #fff !important;
// 	display: none;
// }
.btn-primary.focus,
.btn-primary:focus {
  box-shadow: none !important;
}

.dropdown-item:not([href]) {
  cursor: pointer;
  color: #212529;
}

.float-right .custom-select {
  z-index: +99;
  position: relative;
  cursor: pointer;
}

.apexcharts-legend.position-bottom.apexcharts-align-center {
  margin-top: 10px !important;
}

.apx_chart {
  float: left;
  width: 100%;
}

.btn-w {
  padding: 5px 20px;
  margin-left: 15px;
}

.btn_style {
  background: none;
  border: 0px;
  padding: 0px;
}

.text-solitud {
  color: #808080;
}

.page-title-right2 {
  display: flex;
  align-items: center;
}

.page-title-box .breadcrumb * {
  font-weight: 500 !important;
  font-family: Inter, sans-serif;
}

.description-text {
  white-space: initial;
  font-size: 12px;
  max-width: 300px;
}

.img-circle {
  border-radius: 50%;
}

.profile-details {
  text-align: center;
  position: relative;
}

.switch_profile {
  position: absolute;
  right: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.switch_profile .label-text {
  margin-bottom: 0px;
  padding-right: 5px;
  font-size: 13px;
}

.user-img {
  margin-bottom: 10px;
}

.select2-container {
  width: 100% !important;
}

.popup-w {
  max-width: 700px;
}

.note-editable.card-block {
  max-height: 200px !important;
}

.dataTables_length label {
  font-weight: normal;
  text-align: left;
  white-space: nowrap;
  display: inline-block;
  margin-bottom: .5rem;
  font-size: .9rem !important;
  line-height: 1.5;
  color: #505d69;
}

.dataTables_length label .custom-select {
  width: auto;
  display: inline-block;
}

.dataTables_filter {
  text-align: right;
}

.dataTables_filter label {
  font-weight: normal;
  white-space: nowrap;
  text-align: left;
}

.dataTables_filter label .form-control {
  margin-left: 0.5em;
  display: inline-block;
  width: auto;
}

.table-responsive>.row {
  margin: 0px;
}

.table-responsive>.row>div[class^="col-"]:first-child {
  padding-left: 0;
}

.table-responsive>.row>div[class^="col-"]:last-child {
  padding-right: 0px;
}

.popup-inner .card-title {
  font-weight: 500;
  text-transform: initial;
}

.table-responsive .table.mb-0 {
  margin-bottom: 15px !important;
}

.table-responsive .table.mb-0 {
  margin-bottom: 15px !important;
}

.table-responsive .dataTables_info {
  padding-top: 0.85em;
  white-space: nowrap;
}

.table-responsive .paging_simple_numbers {
  margin: 0;
  white-space: nowrap;
  text-align: right;
}

.table-responsive .paging_simple_numbers .pagination {
  margin: 2px 0;
  white-space: nowrap;
  justify-content: flex-end;
}

.page-link:hover {
  color: $primary-color;
}

.filter_boxs {
  display: flex;
  justify-content: flex-end;
}

.dataTables_filter_select {
  margin-right: 15px;
  margin-bottom: .5rem;
}

.dataTables_filter_select .filter_select {
  display: inline-block;
  width: auto;
  border: 1px solid #ced4da;
  outline: none;
}

.button_detail {
  text-align: end;
}

/**** basic_profile ****/
.basic_profile h6,
.basic_profile p {
  word-break: break-word;
  margin-bottom: 0px;
}

.basic_profile td:first-child {
  padding-left: 0;
}

.basic_profile td:last-child {
  padding-right: 0;
}

.basic_profile td {
  padding-top: 10px;
  padding-bottom: 10px;
}

.basic_profile tr:first-child td {
  border-top: 0px;
}

/**** box_pictures ****/
.disable_image .img-thumbnail {
  opacity: 0.50;
}

.box_pictures {
  padding: .25rem;
  background-color: #f1f5f7;
  border: 1px solid #f1f5f7;
  border-radius: .25rem;
}

.box_pictures .img-thumbnail {
  padding: 0px;
  background: none;
  border: 0px;
  border-radius: 0px;
}

.box_pictures .custom-control-label {
  font-size: 12px;
  line-height: 21px;
}

// 23-02-2021

.users-filter {
  justify-content: flex-end !important;
  padding-bottom: 10px;
}

.table_page   .table th,
.table_page   .table td {
  padding: 7px;
  padding-left: 10px;
  padding-right: 10px;
  font-size: 12px;
}

// slider
// ngx-slick-carousel .slide {
//   margin-right: 24px;    outline: none;
// }
ngx-slick-carousel .slide {
  margin-right: 15px;
  outline: none;
}

ngx-slick-carousel .slick-slider {
  margin: 0px !important;
}

ngx-slick-carousel .slick-slider {
  margin: 0px !important;
}

ngx-slick-carousel .slick-dots li {
  width: auto;
  height: auto;
  margin: 0px;
}

ngx-slick-carousel .slick-dots li {
  padding: 0px !important;
  width: 8px !important;
  height: 8px !important;
  background: #f1f5f7 !important;
  border-radius: 60px;
  margin-right: 8px;
}

ngx-slick-carousel .slick-dots li button {
  display: none;
}

ngx-slick-carousel .slick-dots li.slick-active {
  background: $primary-color !important;
}

ngx-slick-carousel .slick-dots {
  bottom: -14px;
}

ngx-slick-carousel.slick-dotted.slick-slider {
  margin-bottom: 0px;
}

/***** pagination-template  *****/
pagination-template ul.ngx-pagination li {
  width: 34px;
  cursor: pointer !important;
  height: 34px;
  padding: 0px !important;
  text-align: center;
  line-height: 34px;
  border-radius: 60px !important;
  margin: 0 3px;
}

pagination-template ul.ngx-pagination li.pagination-next,
pagination-template ul li.pagination-previous span,
pagination-template ul li.pagination-next a,
pagination-template ul li.pagination-previous span,
pagination-template ul.ngx-pagination li.pagination-previous a {
  font-size: 0px !important;
}

.ngx-pagination .pagination-previous a:before,
.ngx-pagination .pagination-previous.disabled:before {
  content: "\F0141" !important;
  font-family: "Material Design Icons" !important;
}

pagination-template ul li.pagination-next a:after,
pagination-template ul.ngx-pagination li.pagination-previous a:before {
  font-size: .9rem;
}

pagination-template ul.ngx-pagination {
  margin: 2px 0;
  white-space: nowrap;
  justify-content: flex-end;
  padding: 0px;
  display: flex;
}

pagination-template ul.ngx-pagination li a {
  padding: 0px !important;
  display: inline-block;
  line-height: 25px;
}

pagination-template ul.ngx-pagination li a:after {
  line-height: 34px;
  margin: 0px !important;
}

pagination-template ul.ngx-pagination li a .show-for-sr {
  display: none;
}

pagination-template ul.ngx-pagination li.current {
  background-color: $primary-color;
  cursor: default !important;
}

pagination-template ul.ngx-pagination li:hover {
  background-color: $primary-color;
}

pagination-template ul.ngx-pagination li:hover a {
  background: no-repeat;
  color: #fff;
}

.ngx-pagination .pagination-previous a::before,
.ngx-pagination .pagination-previous.disabled::before {
  margin: 0px !important;
}

.ngx-pagination .pagination-next a:after,
.ngx-pagination .pagination-next.disabled:after {
  content: "\F0142" !important;
  font-family: "Material Design Icons" !important;
  font-size: 0.9rem;
}


/*****  End pagination  *****/
.slick-slider .slick-track {
  margin: initial !important;
}

.emoji-mart-bar {
  display: none !important;
}

emoji-mart.emoji-mart.ng-star-inserted {
  border: 0px;
  margin-top: 10px;
}


/***** Ng Select *****/

.multiple-select ng-select {
  padding: 0px !important;
}

.multiple-select .ng-select.ng-select-multiple .ng-select-container.ng-has-value .ng-value-container,
.ng-select.ng-select-multiple .ng-select-container.ng-has-value .ng-clear-wrapper,
.ng-select.ng-select-multiple .ng-select-container.ng-has-value .ng-arrow-wrapper {
  border-top: 0px;
}

.multiple-select .ng-select .ng-select-container {
  border-color: #ced4da !important;
}

.multiple-select .ng-select .ng-select-container:after {
  display: none;
}

.multiple-select .ng-select.ng-select-multiple .ng-select-container .ng-value-container {
  border-top: 0px !important;
}

.multiple-select .ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value {
  background-color: $primary-color;    padding: 0px;    margin: 0 3px 3px 0;
}
.multiple-select .ng-select.ng-select-multiple .ng-select-container.ng-has-value {
  align-items: baseline;
}
.multiple-select .ng-select .ng-select-container {
  border-color: #ced4da !important;
  min-height: 34px !important;
}
.multiple-select  .ng-dropdown-panel .ng-dropdown-panel-items .ng-option {
  line-height: 20px;
  min-height: 20px;
  padding: 5px 10px;
}
.multiple-select .ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected {
  color: #F72374;
}
.multiple-select .ng-dropdown-panel.ng-select-bottom {
  top: 101%;
}
.multiple-select .ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value .ng-value-label {
  font-size: 10px;
}

.multiple-select .ng-select.ng-select-multiple .ng-select-container.ng-has-value .ng-value-container {
  padding-top: 5px !important;
}

.multiple-select .ng-select.ng-select-focused .ng-select-container .ng-arrow-wrapper .ng-arrow {
  color: $primary-color;
}

.multiple-select .ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value .ng-value-icon.left {
  border-right: 1px solid #ffffff;
  background: no-repeat;
  color: #fff !important;
}

// .multiple-select .ng-select .ng-arrow-wrapper .ng-arrow {
//   border-bottom: 0px !important;
// }

.multiple-select .ng-select .ng-arrow-wrapper {
  top:0px !important;
  bottom: auto !important;
  height: 28px;
}

.multiple-select .ng-select.ng-select-multiple .ng-select-container.ng-has-value .ng-arrow-wrapper .ng-arrow {
  margin-bottom: 2px;
}

.multiple-select .ng-dropdown-panel.ng-select-bottom {
  box-shadow: none;
}

.multiple-select .ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-placeholder {
  top: 8px;
}

.multiple-select .ng-select .ng-has-value .ng-placeholder,
.multiple-select .ng-select.ng-select-focused .ng-select-container .ng-value-container .ng-placeholder {
  transform: translateY(-1.48125em) scale(0.75) perspective(100px) translateZ(0.001px);
}
.multiple-select {display: inline-block;width: 100%;}

.multiple-select ng-select {
    margin-right: 10px;
    float: left;
    width: calc(33.33% - 7px);
}

.multiple-select ng-select:last-child {
    margin-right: 0px;
}
.multiple-select .ng-select.ng-select-single .ng-select-container .ng-value-container {
  border: 0px !important;
  padding-left: 10px;
}
.date_picker .calendar-icon {
  display: flex;
  width: 100%;
  justify-content: space-between;
}


.date_picker .calendar-icon .ri-calendar-2-line {
  font-size: 20px;
  position: absolute;
  right: 10px;
  top: 5px;
}

.date_picker {
  cursor: pointer;
}
.daterangepicker .calendar-table td, .daterangepicker .calendar-table th {
  min-width: 100%!important;
  width: 20px!important;
  height: auto!important;
  line-height: 11px!important;
  // font-size: 10px !important;
}

.daterangepicker td.in-range {
  background: #f723740f !important;
}
.daterangepicker td.active, .daterangepicker td.active:hover {
  background: #F72374 !important;
}

.daterangepicker .ranges li.active {
  background: #F72374 !important;
}
.date_picker_sm {
  // margin-bottom: 8px;
}

.date_picker_sm .form-control, .date_picker_sm .input-group {
  width: auto !important;
  display: inline-block;
}

.date_picker_sm .calendar-icon {
  display: flex;
  align-items: center;
  font-size: 13px;
  cursor: pointer;
}

.date_picker_sm .calendar-icon i {
  margin-left: 10px;
}

.date_picker_sm .form-control {
  padding: 4px 12px;
  height: auto !important;
}

 

.date_picker_sm .form-control {
  font-size: 13px;
}
.fancybox-button--zoom {
  display: none !important;
}

// multiple-select-sm
.multiple-select-sm {width: 135px;margin-left: 12px; cursor: pointer;}
.multiple-select-sm ng-select {width: 100%;}
.multiple-select-sm .ri-star-fill {color: #F72374;}
.multiple-select-sm .ng-select.ng-select-single .ng-select-container .ng-value-container {line-height: 14px;}
.multiple-select-sm .ng-select .ng-select-container {height: 28px !important;min-height: 28px !important;}
.multiple-select-sm .ng-select.ng-select-single .ng-select-container .ng-clear-wrapper {bottom: 2px;}
.multiple-select-sm .ng-select .ng-arrow-wrapper .ng-arrow {position: relative;top: 5px;}
.stars label.star:before {
  text-shadow: none !important;
}
/**** responsive ****/
@media (max-width:1280px) {}

@media (min-width: 992px) {
  .chat-leftsidebar {
    min-width: 300px;
  }
}

@media (max-width: 992px) {
  .custom_file {
    margin-bottom: 1rem !important;
  }

  /*********/
}

@media (max-width:769px) {
  .table-responsive .table thead {
    display: none;
  }

  .table-responsive .table tbody td {
    display: block;
    text-align: end;
    position: relative;
    padding-left: 0px;
    padding-right: 0px;
  }

  .table-responsive .table tbody td .user-icon {
    float: none;
  }

  .table-responsive .table tbody td:before {
    content: attr(data-title)":";
    position: absolute;
    left: 5px;
    color: #505d69;
    font-weight: 600;
    font-family: Nunito, sans-serif;
  }

  .table-responsive .table tbody td[data-title="Labels"] {
    height: 40px;
  }

  .table-responsive .table tbody td:first-child {
    border-top: 0px;
  }

  .description-td .description-text {
    margin-left: auto;
  }

  .table-responsive .table tbody td:last-child {
    border-bottom: 1px solid #c9cbce;
  }

  .table-responsive .table tbody tr:last-child td:last-child {
    border-bottom: 0px;
  }

  .dataTables_length label,
  .dataTables_filter,
  .table-responsive .dataTables_info {
    text-align: center;
    width: 100%;
  }

  .table-responsive>.row>div[class^="col-"] {
    padding: 0px;
  }

  .filter_boxs {
    flex-wrap: wrap;
  }

  .dataTables_filter_select {
    margin-right: 0px;
    width: 100%;
    text-align: center;
  }

  .table-style td[data-title="Picture"],
  .table-style td[data-title="Name"] {
    width: 100%;
  }

  /*********/

}


@media (max-width:479px) {
  .filter_s {
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;
  }


  .filter_s .select_box_text {
    width: 100%;
    text-align: center;
    display: block;
    margin-bottom: 10px;
  }

  .filter_s .select_box select {
    font-size: 10px;
  }

  .filter_s .ml-3 {
    margin-left: 0px !important;
  }

  .table-responsive .table tbody td {
    padding-left: 115px !important;
    word-break: break-all;
  }

  /*********/
  .table-style .dataTables_filter .btn-w {
    margin-bottom: 10px;
    margin-right: 0px !important;
  }

  .table-style .dataTables_filter label {
    width: 100%;
    text-align: center;
  }

  .box_pictures .img-thumbnail {
    width: 100%;
  }

  pagination-template ul.ngx-pagination {
    justify-content: center;
  }

  .table-responsive {
    overflow: hidden;
    overflow-x: auto;
  }
  .multiple-select ng-select {
     width:100%; margin-bottom: 10px; margin-right:0px;
}
.multiple-select ng-select:last-child{margin-bottom: 0px;}
.stars2 form {
  float: right !important;
}
}


@media (max-width:379px) {

  .table-responsive .table tbody td {
    padding-left: 115px;
    font-size: 13px;
  }

  .filter_s .select_box select {
    margin-bottom: 10px;
    line-height: 18px;
  }

  .filter_s .select_box+.select_box {
    float: left;
    width: calc(50% - 5px);
  }

  .filter_s .select_box:last-child {
    margin-left: 5px;
  }

  /*********/

}

.api-inline-loader {
  display: block;
  text-align: center;
  margin-top: 30px;
}

.loader-table {
  overflow: hidden;
}


/****  start Radio box ****/
.radio_label .radio-box {
  // width: 20px;
  position: relative;
  cursor: pointer;
  margin-bottom: 0px;
  // height: 20px;
  display: inline-block;
}

.radio_label .radio-box span {
  display: inline-block;
  padding-left: 30px;
  font-size: .9rem;
}

.radio_label .radio-box span:after {
  content: "";
  width: 20px;
  height: 20px;
  display: inline-block;
  position: absolute;
  left: 0px;
  top: 0px;
  background: #ffff;
  border-radius: 60px;
  border: 1px solid #c1c1c1;
}

.radio_label .radio-box span:before {
  content: "";
  width: 12px;
  height: 12px;
  display: inline-block;
  position: absolute;
  left: 4px;
  top: 4px;
  background: #cecece;
  border-radius: 60px;
  z-index: 9;
  opacity: 0;
}

.radio_label .radio-box input {
  position: absolute;
  width: 20px;
  left: 0px;
  opacity: 0;
  z-index: +9999;
  height: 20px;
  cursor: pointer;
}

.radio_label .radio-box input[type=radio]:checked+span:before {
  opacity: 1;
  background-color: $primary-color;
}

.radio_label .radio-box input[type=radio]:checked+span:after {
  border-color: $primary-color;
}

.radio_label .radio-box {
  margin-right: 15px;
  margin-bottom: 10px;
}

.radio_label .radio-box:last-child {
  margin-right: 0px;
}

/**** End Radio kbox ****/

.auth-form-group-custom .form-control {
  padding-right: 60px;
}

.auth-form-group-custom .auti-custom-eye {
  left: auto;
  right: 19px;
  cursor: pointer;
}

.password_box {
  position: relative;
}

.password_box .auti-custom-eye {
  left: auto;
  right: 12px;
  cursor: pointer;
  color: $primary-color !important;
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  font-size: 20px;
}
.password_box input.form-control {
  padding-right: 38px;
}
.password_box .auti-custom-eye:hover{color: #f37daa !important;}
// /* Importing Bootstrap SCSS file. */
// @import "~bootstrap/scss/bootstrap";

// /* Importing Datepicker SCSS file. */
// @import "~ngx-bootstrap/datepicker/bs-datepicker";
.tel-input ngx-intl-tel-input input {color: #96938E;font-size: 16px;border: 1px solid #C5C1BB;border-radius: 8px;height: 45px;padding: 10px 20px !important;width: calc(100% - 80px);}
.tel-input.hide-country-code ngx-intl-tel-input input {width: 100%;}
.tel-input ngx-intl-tel-input .iti__flag-container {color: #96938E;font-size: 16px;border: 1px solid #C5C1BB;border-radius: 8px;height: 45px;padding: 0px !important;width: 70px;position: initial;background: #fff;display: flex; justify-content: center;margin-right: 10px;}
.tel-input.hide-country-code ngx-intl-tel-input .iti__flag-container {display: none;}
.tel-input ngx-intl-tel-input .iti {display: flex;}
.tel-input ngx-intl-tel-input .iti__flag-container .dropdown-toggle {background: none !important;}
.tel-input ngx-intl-tel-input .dropdown-menu {width: 100%;}
.infoForm .tel-input ngx-intl-tel-input input {color: #44423F;}


.descriptions{
  overflow: hidden;
  width: 171px;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  text-decoration: underline;
}